import { toast } from '@/app/wrappers/Toaster/Toaster';
import { MenuButton } from '@/components/ui/menu-button';
import { usePayouts } from '@/services/usePayouts';
import { useWorkers } from '@/services/useWorkers';
import { useGetOneTaxes } from '@/services/useWorkerTaxes';
import DollarAmount from '@/shared/components/DollarAmount';
import { GenericSection } from '@/shared/components/List/List';
import { ListView } from '@/shared/components/ListView/ListView';
import { TaxesStatus, Worker } from '@/shared/types';
import { generateAndDownloadCsv } from '@/shared/util';
import { formatCentsAsDollars } from '@/shared/utils/formatters';
import { Button, Dropdown, Modal, TextInput } from '@checkrx/pay-component-library';
import dayjs from 'dayjs';
import { useState } from 'react';
import ConfirmFileModal from './ConfirmFileModal';
import PreviousIncomeModal from './PreviousIncomeModal';
import { PreviousWorkModal, TaxYears, WorkerWithEarnings } from './taxes.types';
import { taxesStatusRenderMap, TAX_YEARS } from './utils';

export const TaxesTable = () => {
  const [search, setSearch] = useState('');
  const [selectedStatus, setSelectedStatus] = useState<TaxesStatus | ''>('');
  const [taxFilingYear, setTaxFilingYear] = useState<TaxYears>(TAX_YEARS[0]);
  const { data: fetchedWorkers, isError, isLoading } = useWorkers();
  const [selectedWorker, setSelectedWorker] = useState<WorkerWithEarnings>();

  // modals
  const [showPreviousWorkIncomeModal, setShowPreviousWorkIncomeModal] = useState(false);
  const [previousWorkModalType, setPreviousWorkModalType] = useState<PreviousWorkModal>('create');
  const [showConfirmFileModal, setShowConfirmFileModal] = useState(false);
  const {
    data: fetchedPayouts,
    isError: payoutsError,
    isLoading: payoutsLoading,
  } = usePayouts({
    startDate: dayjs(`01-01-${taxFilingYear}`).toDate(),
    endDate: dayjs(`12-31-${taxFilingYear}`).toDate(),
  });

  const { mutateAsync: getOneTaxes } = useGetOneTaxes();

  const filterWorkerByStatus = (w: Worker): boolean => {
    if (selectedStatus === '') return true;
    if (!w?.['1099nec']?.[taxFilingYear]) {
      return selectedStatus === 'NOT_STARTED';
    }
    return w?.['1099nec']?.[taxFilingYear]?.status === selectedStatus;
  };
  const filterWorkerByName = (w: Worker): boolean => {
    if (search === '') return true;
    return (w.profile?.legalName ?? '').toLowerCase().includes(search.toLowerCase());
  };

  const filteredWorkers = fetchedWorkers?.filter((w) => {
    return filterWorkerByStatus(w) && filterWorkerByName(w);
  });

  const workersWithPayoutTotals = filteredWorkers?.reduce<WorkerWithEarnings[]>(
    (acc, curr, idx) => {
      const workerPayouts = fetchedPayouts?.filter(
        (p) => p.workerId === curr.id && p.status === 'completed'
      );
      const earnedTotal = workerPayouts?.reduce((acc, curr) => {
        acc = acc + curr.amountCents;
        return acc;
      }, 0);
      acc[idx] = { ...curr, earnedCentsTotal: earnedTotal ?? 0 };
      return acc;
    },
    [] as WorkerWithEarnings[]
  );

  const handleOpenConfirmFileModal = (worker: WorkerWithEarnings) => {
    setSelectedWorker(worker);
    setShowConfirmFileModal(true);
  };

  const handleOpenPreviousWorkModal = (type: PreviousWorkModal, worker: WorkerWithEarnings) => {
    setPreviousWorkModalType(type);
    setSelectedWorker(worker);
    setShowPreviousWorkIncomeModal(true);
  };

  const handleViewPdf = async (worker: Worker) => {
    toast({
      message: 'Opening 1099-NEC PDF in a new tab',
      type: 'info',
      duration: 5000,
    });
    const newTaxObj = await getOneTaxes({ workerProfileId: worker.id, year: taxFilingYear });
    const link = document.createElement('a');
    link.href = newTaxObj.payeeUrl ?? worker?.['1099nec']?.[taxFilingYear]?.payeeUrl ?? '';
    window.open(link.href);
  };

  const generate1099NecState = (worker: WorkerWithEarnings) => {
    if (worker?.['1099nec']?.[taxFilingYear]?.status === 'CREATED') {
      return 'hidden';
    }
    if (worker?.['1099nec']?.[taxFilingYear]?.status) {
      return 'disabled';
    }
    if (worker.status !== 'active') {
      return 'disabled';
    }
    return 'enabled';
  };

  const sections: GenericSection<WorkerWithEarnings>[][] = [
    [
      {
        id: 'legalName',
        title: 'Worker',
        content: (row) => row.profile?.legalName || row.id,
        width: 240,
      },
      {
        id: 'taxForm',
        title: 'Tax form',
        content: () => '1099-NEC',
        width: 100,
        minScreenWidth: 1480,
      },
      {
        id: 'taxYear',
        title: 'Tax year',
        content: () => taxFilingYear,
        width: 100,
        minScreenWidth: 1380,
      },
      {
        id: 'earnings',
        title: 'Earnings',
        content: (row) => <DollarAmount amountCents={row.earnedCentsTotal} />,
        width: 150,
      },
      {
        id: 'externalEarnings',
        title: 'External earnings',
        content: (row) =>
          row?.['1099nec']?.[taxFilingYear] ? (
            <DollarAmount
              amountCents={Number(row?.['1099nec']?.[taxFilingYear]?.nonPlatformIncome)}
            />
          ) : (
            '----'
          ),
        width: 150,
      },
      {
        id: 'totalEarnings',
        title: 'Total earnings',
        content: (row) =>
          row.earnedCentsTotal ? (
            <DollarAmount
              amountCents={
                Number(row?.['1099nec']?.[taxFilingYear]?.nonPlatformIncome ?? 0) +
                row.earnedCentsTotal
              }
            />
          ) : (
            <DollarAmount amountCents={0} />
          ),
        width: 150,
      },
    ],
    [
      {
        id: 'status',
        title: 'Status',
        content: (row) =>
          taxesStatusRenderMap[
            row.status !== 'active'
              ? 'NON_ACTIVATED_WORKER'
              : row?.['1099nec']?.[taxFilingYear]?.status ?? 'NOT_STARTED'
          ],
        width: 150,
      },
      {
        id: 'actions',
        title: '',
        content: (row) => (
          <MenuButton
            variant="ghost"
            options={[
              {
                label: 'View PDF',
                action: () => handleViewPdf(row),
                state: row?.['1099nec']?.[taxFilingYear]?.payeeUrl ? 'enabled' : 'hidden',
              },
              {
                label: 'Generate 1099-NEC',
                action: () => handleOpenPreviousWorkModal('create', row),
                state: generate1099NecState(row),
              },
              {
                label: 'File 1099',
                action: () => handleOpenConfirmFileModal(row),
                state:
                  row?.['1099nec']?.[taxFilingYear]?.status === 'CREATED' ? 'enabled' : 'hidden',
              },
            ]}
          />
        ),
        width: 40,
      },
    ],
  ];

  const handleGenerateCsv = () => {
    generateAndDownloadCsv(workersWithPayoutTotals ?? [], (worker) => ({
      id: worker.id,
      email: worker?.profile?.email,
      phoneNumber: worker?.profile?.phoneNumber,
      firstName: worker?.profile?.legalName?.split(' ')[0] || '',
      // We want the last name to include the rest of the names
      lastName: worker?.profile?.legalName?.split(' ').slice(1).join(' ') || '',
      taxForm: '1099-NEC',
      taxYear: taxFilingYear,
      onPlatformEarnings: formatCentsAsDollars(worker.earnedCentsTotal),
      offPlatformEarnings: formatCentsAsDollars(
        Number(worker?.['1099nec']?.[taxFilingYear]?.nonPlatformIncome ?? 0)
      ),
      totalEarnings: formatCentsAsDollars(
        worker.earnedCentsTotal +
          Number(worker?.['1099nec']?.[taxFilingYear]?.nonPlatformIncome ?? 0)
      ),
      deliveryMethod: 'Paperless',
      workerStatus: worker?.['1099nec']?.[taxFilingYear]?.status ?? 'NOT_STARTED',
      error: worker?.['1099nec']?.[taxFilingYear]?.error ?? '',
    }));
  };

  return (
    <div className="flex flex-col gap-4 bg-primaryWhite p-4 h-full">
      <ListView
        sections={sections}
        data={workersWithPayoutTotals}
        loading={isLoading || payoutsLoading}
        error={isError || payoutsError}
        header={
          <div className="flex w-full min-w-fit h-fit items-start justify-between">
            <div className="flex flex-row items-center justify-start w-fit gap-5">
              <TextInput
                value={search}
                width={400}
                placeholder="Search by name"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearch(e.target.value);
                }}
              />
              <Dropdown
                options={[
                  { label: 'Any Status', value: '' },
                  { label: 'Not started', value: 'NOT_STARTED' },
                  { label: 'Created', value: 'CREATED' },
                  { label: 'Filed', value: 'FILED' },
                  { label: 'Accepted', value: 'ACCEPTED' },
                  { label: 'Rejected', value: 'REJECTED' },
                  { label: 'Queued', value: 'QUEUED' },
                  { label: 'Failed', value: 'FAILED' },
                ]}
                closeOnOutsideClick
                onSelect={(opt) => {
                  setSelectedStatus(opt.value as TaxesStatus);
                }}
              />
              <Dropdown
                options={TAX_YEARS.map((y) => ({ label: y.toString(), value: y.toString() }))}
                closeOnOutsideClick
                onSelect={(opt) => setTaxFilingYear(Number(opt.value) as TaxYears)}
              />
              <div className="flex items-center gap-4 justify-end">
                <Button text="Generate CSV" onClick={handleGenerateCsv} colorVariant="accent" />
              </div>
            </div>
          </div>
        }
      />

      <Modal
        isOpen={showPreviousWorkIncomeModal}
        close={() => {
          setShowPreviousWorkIncomeModal(false);
        }}
        headerText={
          previousWorkModalType === 'create'
            ? 'Previous income before using Checkr Pay'
            : 'Request correction for 1099-NEC'
        }
        subHeaderText={
          previousWorkModalType === 'create'
            ? 'Please enter the previous income earned before using Checkr Pay'
            : 'Please enter the previous income earned you would like to update to'
        }
        modalContent={
          <PreviousIncomeModal
            worker={selectedWorker as WorkerWithEarnings}
            taxYear={taxFilingYear}
            type={previousWorkModalType}
            onClose={() => setShowPreviousWorkIncomeModal(false)}
          />
        }
      />
      <Modal
        isOpen={showConfirmFileModal}
        close={() => {
          setShowConfirmFileModal(false);
        }}
        headerText="Confirm your filing"
        subHeaderText={`You are filing for ${selectedWorker?.profile?.legalName}
        for the ${taxFilingYear} tax year`}
        modalContent={
          <ConfirmFileModal
            worker={selectedWorker as WorkerWithEarnings}
            taxYear={taxFilingYear}
            onClose={() => setShowConfirmFileModal(false)}
            onViewPdf={handleViewPdf}
          />
        }
      />
    </div>
  );
};

