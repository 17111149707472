import { MenuOption } from '@/components/ui/menu-button';
import { Sheet, SheetContent, SheetHeader, SheetTitle } from '@/components/ui/sheet';
import { useCustomer, useCustomerProfilePermissions } from '@/services/useCustomerProfile';
import { Payout, PayoutStatus, TabPermissions } from '@/shared/types';
import { copyText } from '@/shared/utils/copy-text';
import { formatCentsAsDollars } from '@/shared/utils/formatters';
import { Modal } from '@checkrx/pay-component-library';
import { clsx } from 'clsx';
import dayjs from 'dayjs';
import { useState } from 'react';
import ConfirmPayoutCancellationModal from './ConfirmPayoutCancellationModal';
import ConfirmRepayPayoutModal from './ConfirmRepayPayoutModal';
import { PayoutStatusText } from './PayoutStatusText';

interface PayoutSheetProps {
  payout?: Payout;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

const InfoSection = ({ title, children }: { title: string; children: React.ReactNode }) => (
  <div className="space-y-2">
    <h3 className="text-sm font-medium text-labelGrey">{title}</h3>
    <div className="bg-backgroundGrey p-4 rounded-lg space-y-2">{children}</div>
  </div>
);

const InfoRow = ({
  label,
  value,
  alignCenter = false,
}: {
  label: string;
  value: React.ReactNode;
  alignCenter?: boolean;
}) => (
  <p className={clsx('text-sm flex justify-between gap-4', alignCenter && 'items-center')}>
    <span className="text-labelGrey">{label}:</span>
    <span className="font-medium">{value}</span>
  </p>
);

export const PayoutSheet = ({ payout, open, onOpenChange }: PayoutSheetProps) => {
  const [showConfirmPayoutCancellationModal, setShowConfirmPayoutCancellationModal] =
    useState(false);
  const [showConfirmRepayPayoutModal, setShowConfirmRepayPayoutModal] = useState(false);

  const { data: permissions } = useCustomerProfilePermissions();
  const { data: customer } = useCustomer();

  if (!payout) return null;

  const reversiblePayoutStatuses = [PayoutStatus.Completed];
  const canRepayPayout = (payout: Payout) => {
    if (permissions?.executeRepayments !== TabPermissions.write) return false;
    if (!reversiblePayoutStatuses.includes(payout.status)) return false;
    if (!customer?.canUseRepayments) return false;
    return true;
  };

  const cancelablePayoutStatuses = [PayoutStatus.Pending, PayoutStatus.Queued, PayoutStatus.Failed];
  const canCancelPayout = (payout: Payout) => {
    return (
      permissions?.payoutsTab === TabPermissions.write &&
      cancelablePayoutStatuses.includes(payout.status)
    );
  };

  const menuOptions: MenuOption[] = [
    {
      label: 'Copy payout ID',
      action: () => copyText(payout.id),
    },
    {
      label: 'Copy worker ID',
      action: () => copyText(payout.workerId),
    },
    {
      label: 'Cancel payout',
      state: canCancelPayout(payout) ? 'enabled' : 'hidden',
      action: () => setShowConfirmPayoutCancellationModal(true),
    },
    {
      label: 'Repay payout',
      state: canRepayPayout(payout) ? 'enabled' : 'hidden',
      action: () => setShowConfirmRepayPayoutModal(true),
    },
  ];

  return (
    <>
      <Sheet open={open} onOpenChange={onOpenChange}>
        <SheetContent menuOptions={menuOptions}>
          <SheetHeader>
            <SheetTitle>Payout Details</SheetTitle>
          </SheetHeader>

          <div className="mt-6 space-y-6">
            <InfoSection title="Worker Information">
              <InfoRow label="Email" value={payout.workerResource?.profile?.email} />
              <InfoRow label="Name" value={payout.workerResource?.profile?.legalName} />
            </InfoSection>

            <InfoSection title="Payout Information">
              <InfoRow
                label="Amount"
                value={formatCentsAsDollars(payout.amountCents)}
                alignCenter
              />
              <InfoRow label="Status" value={<PayoutStatusText payout={payout} />} alignCenter />
              <InfoRow label="Description" value={payout.description} />
              <InfoRow label="Created" value={dayjs(payout.createdAt).format('MM/DD/YYYY')} />
              {payout.issuanceStrategy?.autoIssueOn && (
                <InfoRow
                  label="Auto Issue On (local)"
                  value={dayjs(payout.issuanceStrategy.autoIssueOn).format('MM/DD/YYYY, h:mma')}
                />
              )}
              {payout.expectedCompletionDate && (
                <InfoRow
                  label="Expected Completion Date"
                  value={dayjs(payout.expectedCompletionDate).format('MM/DD/YYYY')}
                />
              )}
              {payout.completedAt && (
                <InfoRow
                  label="Completed"
                  value={dayjs(payout.completedAt).format('MM/DD/YYYY, h:mma')}
                />
              )}
            </InfoSection>

            {payout.metadata && (
              <InfoSection title="Metadata">
                <pre className="text-sm overflow-auto whitespace-pre-wrap break-words max-h-[300px]">
                  {JSON.stringify(payout.metadata, null, 2)}
                </pre>
              </InfoSection>
            )}
          </div>
        </SheetContent>
      </Sheet>

      <Modal
        isOpen={showConfirmRepayPayoutModal}
        close={() => setShowConfirmRepayPayoutModal(false)}
        headerText="Repay a payout"
        subHeaderText={`You are repaying a payout for ${payout?.workerResource?.profile?.legalName}`}
        modalContent={
          <ConfirmRepayPayoutModal
            payout={payout}
            onClose={() => setShowConfirmRepayPayoutModal(false)}
          />
        }
      />

      <Modal
        isOpen={showConfirmPayoutCancellationModal}
        close={() => setShowConfirmPayoutCancellationModal(false)}
        headerText="Cancel payout"
        subHeaderText={`You are canceling a payout for ${payout?.workerResource?.profile?.legalName}`}
        modalContent={
          <ConfirmPayoutCancellationModal
            payout={payout}
            onClose={() => setShowConfirmPayoutCancellationModal(false)}
          />
        }
      />
    </>
  );
};

